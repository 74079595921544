import simone from "./assets/SIMONE.png";

export function Logo() {
  return (
    <div 
      style={{
        display: "flex",
        justifyContent: 'center',
        position: "absolute",
        bottom: "100px",
        width: "100%",
      }}
    >
      <img
        style={{
          maxHeight: '80px',
          maxWidth: '155px'
        }}
        src={simone}
      />
    </div>
  );
}
