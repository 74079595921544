import React from "react";

function SpeakerIcon() {
  return (
    <svg
      width="20"
      height="20"
      viewBox="0 0 20 20"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M9.12991 4.22311L5.26525 7.71535H3.41149C2.62537 7.71535 2 8.24395 2 9.00737V10.8531L2.00578 10.9807C2.0708 11.6993 2.67643 12.3297 3.41149 12.3297H5.26619L9.14319 15.7851C9.355 15.9926 9.69858 16.0603 9.99016 15.9419C10.2851 15.8223 10.4683 15.5399 10.4689 15.2313L10.4689 4.77139C10.4683 4.46179 10.2851 4.17862 9.99016 4.05894C9.69858 3.94059 9.355 4.00305 9.12991 4.22311ZM9.52795 5.11655V14.9267L5.76921 11.529C5.68232 11.4504 5.56848 11.4068 5.45031 11.4068H3.41149C3.18194 11.4068 2.94099 11.1379 2.94099 10.8531V9.00737C2.94099 8.78663 3.11657 8.63822 3.41149 8.63822H5.45031C5.56848 8.63822 5.68232 8.59461 5.76921 8.51606L9.52795 5.11655ZM15.6532 4.66938C15.4624 4.49642 15.1647 4.50793 14.9884 4.69509C14.812 4.88225 14.8237 5.17419 15.0146 5.34715C16.3179 6.5284 17.059 8.18978 17.059 9.93024C17.059 11.6707 16.3179 13.3321 15.0146 14.5133C14.8237 14.6863 14.812 14.9782 14.9884 15.1654C15.1647 15.3526 15.4624 15.3641 15.6532 15.1911C17.1493 13.8352 18 11.9281 18 9.93024C18 7.93239 17.1493 6.02532 15.6532 4.66938ZM13.0961 5.95963C13.2533 5.7567 13.5484 5.71716 13.7554 5.87132C15.049 6.83518 15.8087 8.33688 15.8087 9.93024C15.8087 11.5236 15.049 13.0253 13.7554 13.9892C13.5484 14.1433 13.2533 14.1038 13.0961 13.9009C12.9389 13.6979 12.9792 13.4085 13.1861 13.2543C14.2456 12.4649 14.8678 11.2351 14.8678 9.93024C14.8678 8.62536 14.2456 7.39554 13.1861 6.60619C12.9792 6.45203 12.9389 6.16255 13.0961 5.95963ZM12.1572 7.4037C11.947 7.25381 11.6528 7.29937 11.4999 7.50546C11.3471 7.71156 11.3936 8.00015 11.6037 8.15005C13.0228 9.1623 13.0216 10.6997 11.6038 11.7104C11.3936 11.8602 11.3471 12.1488 11.4999 12.3549C11.6526 12.5611 11.9469 12.6067 12.1571 12.4569C14.0915 11.0779 14.0933 8.78475 12.1572 7.4037Z"
        fill="#606B85"
      />
    </svg>
  );
}

export default React.memo(SpeakerIcon);
